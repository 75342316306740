import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import { translations } from '../constants/table-localization';
import * as nationalityActions from '../store/nationality/nationality.actions';
import * as personActions from '../store/person/person.actions';
import * as genderActions from '../store/gender/gender.actions';
import * as sportActions from '../store/sport/sport.actions';
import moment from 'moment';
import { gendersSelector, peopleSelector } from '../store/selectors';
import { nationalitiesSelector } from '../store/selectors';
import { sportsSelector } from '../store/selectors';
import { withSnackbar } from 'notistack';
import ImageUploadDialog from '../components/modals/ImageUploadDialog';
import { setSuccessMsg } from '../store/app/app.actions';
import { defaultColumnsConstructor } from '../constants/table/peopleListColumns';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  select: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const PeopleList = ({
  fetchNationalities,
  nationalities,
  fetchGenders,
  genders,
  fetchSports,
  sports,
  fetchPeople,
  people,
  addPerson,
  updatePerson,
  deletePerson,
  uploadPersonImage,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(20);

  const tableOptions = {
    search: false,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
  };

  useEffect(() => {
    if(nationalities.length === 0) fetchNationalities();
    if(genders.length === 0) fetchGenders();
    if(people.length === 0) fetchPeople();
    if(sports.length === 0) fetchSports(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({});

  const onImageUploadSubmit = (image) => {
    uploadPersonImage(selectedPerson.id, image).then(() => {
      setSuccessMsg('Imagen cargada correctamente')
      fetchPeople();
    });
    setImageUploadDialogOpen(false);
  };
  const onImageUploadClose = () => {
    setImageUploadDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <ImageUploadDialog
        open={imageUploadDialogOpen}
        onSubmit={onImageUploadSubmit}
        onClose={onImageUploadClose}
        fileName={`profile_${selectedPerson.id}`}
      />
      <MaterialTable
        title={
          <Typography variant="h5" color="textPrimary" align="center">
            Listado de personas
          </Typography>
        }
        onChangeRowsPerPage={(value) => {
          setPageSize(value);
        }}
        columns={defaultColumnsConstructor(nationalities, genders, sports)}
        options={tableOptions}
        localization={translations}
        data={people}
        actions={[
          {
            onClick: (event, rowData) => {},
            icon: 'list_alt',
            tooltip: 'Ver detalle',
          },
          {
            onClick: (event, rowData) => {
              setSelectedPerson(rowData);
              setImageUploadDialogOpen(true);
            },
            icon: 'add_a_photo',
            tooltip: 'Cargar foto',
          },
        ]}
        editable={{
          isEditable: (rowData) => true,
          isDeletable: (rowData) => true,
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              const {
                firstName,
                lastName,
                email,
                birthOfDate,
                gender,
                nationality,
                sports,
              } = newData;
              if (
                !firstName ||
                !lastName ||
                !email ||
                !birthOfDate ||
                !gender ||
                !nationality ||
                !sports
              ) {
                reject();
              } else {
                addPerson({
                  firstName,
                  lastName,
                  email,
                  birthOfDate: moment(birthOfDate).format('YYYY-MM-DD'),
                  gender: gender.id,
                  nationality: nationality.id,
                  addSports: sports,
                }).then((res) => {
                  setSuccessMsg('Persona agregada correctamente')                 
                })
                .finally(()=> {
                  fetchPeople();
                  resolve()
                });
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const {
                id,
                firstName,
                lastName,
                email,
                birthOfDate,
                gender,
                nationality,
                sports,
              } = newData;
              if (
                !id ||
                !firstName ||
                !lastName ||
                !email ||
                !birthOfDate ||
                !gender ||
                !nationality ||
                !sports
              ) {
                reject();
              } else {
                updatePerson({
                  id,
                  firstName,
                  lastName,
                  email,
                  birthOfDate: moment(birthOfDate).format('YYYY-MM-DD'),
                  gender: gender.id,
                  nationality: nationality.id,
                  addSports: sports,
                }).then((res) => {
                  setSuccessMsg('Persona modificada correctamente')
                })
                .finally(()=> {
                  fetchPeople();
                  resolve()
                });
              }
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deletePerson(oldData.id).then((res) => {
                setSuccessMsg('Persona eliminada correctamente')
              })
              .finally(()=> {
                fetchPeople();
                resolve()
              });
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  nationalities: nationalitiesSelector(state),
  people: peopleSelector(state),
  genders: gendersSelector(state),
  sports: sportsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...nationalityActions,
      ...personActions,
      ...genderActions,
      ...sportActions,
      setSuccessMsg,
    },
    dispatch
  );

export default withSnackbar(
  compose(connect(mapStateToProps, mapDispatchToProps))(PeopleList)
);
