export const AUTH_ACTION_TYPES = {
  GOOGLE_AUTH_LOADING: 'GOOGLE_AUTH_LOADING',
  FETCH_USER_START: 'FETCH_USER_START',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',
  UNSUSCRIBE_SUCCESS: 'UNSUSCRIBE_SUCCESS',
  UNSUSCRIBE_ERROR: 'UNSUSCRIBE_ERROR',
};
