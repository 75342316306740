import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import ConfirmationDialog from '../components/ConfimationDialog';
import {
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@material-ui/core';

import MaterialTable from 'material-table';
import { Edit, DeleteSweep } from '@material-ui/icons';

import * as anthropometricDataActions from '../store/anthopometric-data/anthropometricData.actions';
import { translations } from '../constants/table-localization';
import { defaultColumns } from '../constants/table/anthropometricDataListColumns';
import { getAllAnthropometricData } from '../store/selectors';
import { setSuccessMsg } from '../store/app/app.actions';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(3),
    fontFamily: 'Arial',
    '& .MuiTableHead-root': {
      borderTop: '1px solid #e0e0e0',
      borderBottom: '2px solid #e0e0e0',
    },
  },
  categorySelect: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  stateIcon: {
    paddingLeft: '20%',
  },
  actionIcon: {
    paddingLeft: '120px',
    color: 'action',
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Arial',
    width: '130px',
    paddingLeft: '0%',
    color: '#3c9ee5',
  },
  list: {
    paddingLeft: '0%',
  },
}));

export const AnthropometricDataList = ({
  allAnthropometricData,
  fetchAllAnthropometricData,
  deleteAnthropometricData,
  setAnthropometricData,
  setShowForm,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ADSelected, setADSelected] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setLoading(true); 
    if(allAnthropometricData.length === 0) {
      fetchAllAnthropometricData() 
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {setLoading(false)}
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

 
  const tableOptions = {
    search: true,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
    tableLayout: '',
  };

  const scrollToTop = () => window.scrollTo(0, 0);

  const handleClick = (event, AD) => {
    setADSelected(AD);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnEdit = () => {
    setAnthropometricData(ADSelected);
    scrollToTop();
  };

  const handleOnDelete = () => {
    if (ADSelected) {
      setLoading(true);
      deleteAnthropometricData(ADSelected.id)
        .then(res => {
          setSuccessMsg('Datos antropométricos eliminados correctamente.');
          setLoading(false);
          handleClose();
          setOpenConfirm(false);
          fetchAllAnthropometricData();
        })
        .catch(() => {
          setLoading(false);
          handleClose();
          setOpenConfirm(false);
        });
    }
  };

  const confirmDelete = () => {
    setConfirmMessage(() => (
      <span>
        ¿Está seguro que desea <b>eliminar</b> estos datos antropométricos?
      </span>
    ));
    setOpenConfirm(true);
  };

  return (
    <div className={classes.container}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onBlur={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem
            button
            disabled={loading}
            onClick={() => {
              handleOnEdit();
              setShowForm(true);
            }}
          >
            <ListItemText primary="Editar" />
            <Edit className={classes.actionIcon} color="action" />
          </ListItem>
          <ListItem button disabled={loading} onClick={confirmDelete}>
            <ListItemText primary="Eliminar" />
            <DeleteSweep className={classes.actionIcon} color="action" />
          </ListItem>
        </List>
      </Popover>
      <ConfirmationDialog
        loading={loading}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        message={confirmMessage}
        onConfirm={handleOnDelete}
        setLoading={setLoading}
      />
      <MaterialTable
        className={classes.table}
        title={
          <Typography variant="h6" color="textPrimary" align="center">
            Datos Antropométricos
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={defaultColumns}
        options={tableOptions}
        localization={translations}
        isLoading={loading}
        data={allAnthropometricData}
        actions={[
          {
            icon: 'more_vert',
            tooltip: 'más',
            iconProps: { color: 'action' },
            onClick: (event, rowData) => {
              handleClick(event, rowData);
            },
          },
        ]}
        editable={{
          isEditable: rowData => false,
          isDeletable: rowData => true,
        }}
      />
    </div>
  );
};

AnthropometricDataList.propTypes = {
  setShowForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  allAnthropometricData: getAllAnthropometricData(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...anthropometricDataActions,
      setSuccessMsg
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AnthropometricDataList
);
