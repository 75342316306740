import { TEAM_ACTION_TYPES } from './team.types';
import { teamService } from '../../services/team.service';

export const fetchTeams = () => async dispatch => {
  try {
    const response = await teamService.getTeams();
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.FETCH_TEAMS_SUCCESS,
          payload: response
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.FETCH_TEAMS_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchMyTeams = () => async dispatch => {
  try {
    const response = await teamService.getMyTeams();
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.FETCH_MY_TEAMS_SUCCESS,
          payload: response
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.FETCH_MY_TEAMS_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTeamPlayers = id => async dispatch => {
  try {
    const response = await teamService.getTeamPlayers(id)
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS,
          payload: response
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTeamMembers = id => async (dispatch) => {
  try {
    const response = await teamService.getTeamMembers(id);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetTeam = () => dispatch => {
  return dispatch({
    type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS,
    payload: [],
  });
};

export const resetTeamPlayers = () => dispatch => {
  return dispatch({
    type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS,
    payload: [],
  });
};

export const resetTeamMembers = () => dispatch => {
  return dispatch({
    type: TEAM_ACTION_TYPES.FETCH_TEAM_MEMBERS_SUCCESS,
    payload: [],
  });
};

export const addTeam = team => async dispatch => {
  try {
    const response = await teamService.addTeam(team)
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.ADD_TEAM_SUCCESS,
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.ADD_TEAM_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTeam = team => async dispatch => {
  try {
    const response = await teamService.updateTeam(team.id, team);
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.UPDATE_TEAM_SUCCESS,
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.UPDATE_TEAM_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTeam = id => async dispatch => {
  try {
    const response = await teamService.deleteTeam(id)
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.DELETE_TEAM_SUCCESS,
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.DELETE_TEAM_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchPositionsByTeam = id => async dispatch => {
  try {
    const response = await teamService.getTeamPositions(id)
    response
      ? dispatch({
          type: TEAM_ACTION_TYPES.FETCH_TEAM_POSITION_BY_TEAM_SUCCESS,
          payload: response
        })
      : dispatch({
          type: TEAM_ACTION_TYPES.FETCH_TEAM_POSITION_BY_TEAM_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setTeamValidityDate = validityDate => dispatch => {
  dispatch({
    type: TEAM_ACTION_TYPES.SET_TEAM_VALIDITY_DATE,
    payload: validityDate
  });
}