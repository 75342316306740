import { ANTHROPOMETRIC_DATA_ACTION_TYPES } from './anthropometricData.types';
import { anthropometricDataService } from '../../services/anthropometricData.service';

export const setAllAnthropometricData = anthropometricData => ({
  type: ANTHROPOMETRIC_DATA_ACTION_TYPES.SET_ANTHROPOMETRIC_DATA,
  payload: anthropometricData,
});

// FETCH
export const fetchAllAnthropometricData = () => async dispatch => {
  try {
    const response = await anthropometricDataService.getAllAnthropometricData();
    response
      ? dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ANTHROPOMETRIC_DATA_SUCCESS,
        })
      : dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ANTHROPOMETRIC_DATA_ERROR,
        });
    dispatch(setAllAnthropometricData(response));
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAnthropometricPlayer = (id) => async (dispatch) => {
  try {
    const response = await anthropometricDataService.getAnthropometricPlayer(id);
    response
      ? dispatch({
        type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ANTHROPOMETRIC_PLAYER_SUCCESS
      })
      : dispatch({
          type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ANTHROPOMETRIC_PLAYER_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const fetchAllStage = () => async dispatch => {
  try {
    const response = await anthropometricDataService.getAllStage();
    response
      ? dispatch({
          type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ALL_STAGE_SUCCESS,
        })
      : dispatch({
          type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ALL_STAGE_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAllMadurityIndex = () => async dispatch => {
  try {
    const response = await anthropometricDataService.getAllMadurityIndex();
    response
      ? dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ALL_MADURITY_INDEX_SUCCESS,
        })
      : dispatch({
          type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ALL_MADURITY_INDEX_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAllPurpose = () => async dispatch => {
  try {
    const response = await anthropometricDataService.getAllPurpose();
    response
      ? dispatch({
          type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ALL_PURPOSE_SUCCESS,
        })
      : dispatch({
          type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ALL_PURPOSE_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// PUT
export const updateAnthropometricData = (
  anthropometricData,
  id
) => async dispatch => {
  try {
    const response = await anthropometricDataService.updateAnthropometricData(
      id,
      anthropometricData
    );
    response
      ? dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.UPDATE_ANTHROPOMETRIC_DATA_SUCCESS,
        })
      : dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.UPDATE_ANTHROPOMETRIC_DATA_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// POST
export const addAnthropometricData = (anthropometricData) => async dispatch => {
  try {
    const response = await anthropometricDataService.saveAnthropometricData(anthropometricData);
    response
      ? dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.SAVE_ANTHROPOMETRIC_DATA_SUCCESS,
        })
      : dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.SAVE_ANTHROPOMETRIC_DATA_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
// DELETE

export const deleteAnthropometricData = id => async dispatch => {
  try {
    const response = await anthropometricDataService.deleteAnthropometricData(id);
    response
      ? dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.DELETE_ANTHROPOMETRIC_DATA_SUCCESS,
        })
      : dispatch({
          type:
            ANTHROPOMETRIC_DATA_ACTION_TYPES.DELETE_ANTHROPOMETRIC_DATA_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
