import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomAvatar from '../components/Avatar';
import PhysicalAttributes from '../components/PhysicalAttributes';
import CustomLineBarChartWithLegend from '../components/charts/barLineChart/CustomLineBarChartWithLegend';
import Notes from '../components/Notes';
import * as profileActions from '../store/profile/profile.actions';
import * as personActions from '../store/person/person.actions';
import { fetchAnthropometricPlayer } from '../store/anthopometric-data/anthropometricData.actions';
import ImageUploadDialog from '../components/modals/ImageUploadDialog';
import { BASE_URL } from '../constants/config';
import defaultImage from '../utils/defaultImage';
import { setSuccessMsg } from '../store/app/app.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  notesContainer: {
    marginTop: '10px',
  },
}));

const Profile = ({
  match: {
    params: { id, idPerson },
  },
  fetchTeamPlayer,
  fetchRPEPlayer,
  fetchAnthropometricPlayer,
  fetchWellnessHistoricalPlayer,
  uploadPersonImage,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [wellnessHistoricalPlayer, setWellnessHistorialPlayer] = useState([]);

  const [RPEPlayer, setRPEPlayer] = useState(null);
  const [anthropometricPlayer, setAnthropometricPlayer] = useState(null);
  const [teamPlayer, setTeamPlayer] = useState(null);
  
  useEffect(() => {
    fetchTeamPlayer(id).then((res)=> setTeamPlayer(res));
    fetchRPEPlayer(id).then((res)=> setRPEPlayer(res));
    fetchAnthropometricPlayer(idPerson).then((res)=> setAnthropometricPlayer(res))
    fetchWellnessHistoricalPlayer(id).then(
      (data) => isMounted && setWellnessHistorialPlayer(data)
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageUploadSubmit = (image) => {
    uploadPersonImage(teamPlayer.person.id, image).then((res) => {
      setSuccessMsg('Imagen cargada correctamente')
      fetchTeamPlayer(id);
    });
    setImageUploadDialogOpen(false);
  };
  const onImageUploadClose = () => {
    setImageUploadDialogOpen(false);
  };

  const onRPEReload = (filters) => {
    const { days, offsetCl, cantDaysAl, cantDaysMonotony } = filters;
    let result= null;
    fetchRPEPlayer(id, days, offsetCl, cantDaysAl, cantDaysMonotony).then((res)=> {setRPEPlayer(res); result=res})
    return result;

  };

  const onWellnessReload = (filters) => {
    const { days } = filters;
    let result= null;
    fetchWellnessHistoricalPlayer(id, days).then((res)=> {setWellnessHistorialPlayer(res); result = res});
    return result;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4} md={3}>
          <Card elevation={3}>
            <CardContent>
              {teamPlayer?.person && (
                <div
                  style={{ cursor: 'pointer' }}
                  title="Cargar foto"
                  onClick={() => setImageUploadDialogOpen(true)}
                >
                  <CustomAvatar
                    image={
                      teamPlayer.person.image
                        ? BASE_URL + teamPlayer.person.image
                        : defaultImage()
                    }
                    size="large"
                  />
                </div>
              )}
              {teamPlayer?.team && (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {teamPlayer?.team.name}
                </Typography>
              )}
              {teamPlayer?.player && teamPlayer.player?.position && (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {teamPlayer.player.position.description}
                </Typography>
              )}
              {teamPlayer?.person && (
                <Typography
                  variant="h6"
                  gutterBottom
                  color="textPrimary"
                  style={{ textAlign: 'center' }}
                >
                  {teamPlayer.person.firstName.toUpperCase()}
                  <span> {teamPlayer.person.lastName.toUpperCase()}</span>
                </Typography>
              )}
            </CardContent>
          </Card>
          <div className={classes.notesContainer}>
            {anthropometricPlayer && anthropometricPlayer[0] ? (
              <PhysicalAttributes
                title="ATRIBUTOS FISICOS"
                weight={anthropometricPlayer[0].weight}
                height={anthropometricPlayer[0].height}
                muscleMassKg={anthropometricPlayer[0].muscleMassKg}
                muscleMassPercentage={anthropometricPlayer[0].muscleMassPercentage}
                age={anthropometricPlayer[0].date}
              />
            ) : null}
          </div>
          <div className={classes.notesContainer}>
            <Notes title="Observaciones" />
          </div>
        </Grid>

        <Grid container item xs={8} md={9} spacing={2}>
          <Grid container>
            <Grid item style={{ width: '100%', minWidth: '800px' }}>
              <div style={{ padding: '10px 0px' }}>
                <CustomLineBarChartWithLegend
                  title="Wellness Histórico"
                  data={wellnessHistoricalPlayer.data}
                  hasFilters
                  onReload={onWellnessReload}
                  hasArea={false}
                  hasLegend={false}
                  type="wellness"
                />
              </div>
            </Grid>
            <Grid item style={{ width: '100%', minWidth: '800px' }}>
              {RPEPlayer && (
                <div style={{ padding: '10px 0px' }}>
                  <CustomLineBarChartWithLegend
                    title="Carga interna / Carga externa"
                    data={RPEPlayer}
                    hasFilters
                    type='RPE'
                    onReload={onRPEReload}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {teamPlayer?.person && (
        <ImageUploadDialog
          open={imageUploadDialogOpen}
          onSubmit={onImageUploadSubmit}
          onClose={onImageUploadClose}
          fileName={`profile_${teamPlayer.person.id}`}
        />
      )}
    </div>
  );
};

Profile.propTypes = {
  match:PropTypes.shape({
    params: PropTypes.shape({ 
      id: PropTypes.number,
      idPerson: PropTypes.number,
    }),
  }),
  fetchTeamPlayer: PropTypes.func.isRequired,
  fetchRPEPlayer: PropTypes.func.isRequired,
  fetchAnthropometricPlayer: PropTypes.func.isRequired,
  fetchWellnessHistoricalPlayer: PropTypes.func.isRequired,
  uploadPersonImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...profileActions, ...personActions, fetchAnthropometricPlayer,setSuccessMsg, }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(Profile);
