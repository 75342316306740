import { TEAM_ACTION_TYPES } from './team.types';

const initialState = {
  teams: [],
  myTeams: {},
  teamPlayers: [],
  teamRoles: [],
  positions: [],
  positionsByTeam: [],
  error: '',
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case TEAM_ACTION_TYPES.FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
      };
    case TEAM_ACTION_TYPES.FETCH_MY_TEAMS_SUCCESS:
      return {
        ...state,
        myTeams: action.payload,
      };
    case TEAM_ACTION_TYPES.SET_TEAM_VALIDITY_DATE:
      return {
        ...state,
        teamDetail: {...state.teamDetail, validityDate: action.payload},
      };
    case TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS:
      return {
        ...state,
        teamPlayers: action.payload,
      };
    case TEAM_ACTION_TYPES.FETCH_TEAM_ROLES_SUCCESS:
      return {
        ...state,
        teamRoles: action.payload,
      };
    case TEAM_ACTION_TYPES.FETCH_TEAM_POSITION_SUCCESS:
      return {
        ...state,
        positions: action.payload,
      };
    case TEAM_ACTION_TYPES.FETCH_TEAM_POSITION_BY_TEAM_SUCCESS:
      return {
        ...state,
        positionsByTeam: action.payload,
      };
    case TEAM_ACTION_TYPES.TEAM_ERROR:
      return {
        ...state,
        error: action.msg,
      };
    default:
      return state;
  }
}
