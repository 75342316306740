import { AUTH_ACTION_TYPES } from './auth.types';
import { authService } from '../../services';
import { runFetch } from '../../utils/fetchHandler';
import defaultInstance from '../../utils/defaultInstance';

export const authRequested = value => dispatch => {
  return dispatch({
    type: AUTH_ACTION_TYPES.GOOGLE_AUTH_LOADING,
    payload: value,
  });
};

export const authSuccess = value => ({
  type: AUTH_ACTION_TYPES.FETCH_USER_SUCCESS,
  payload: value,
});

export const logoutSuccess = () => ({
  type: AUTH_ACTION_TYPES.LOGOUT_SUCCESS,
});

export const loginSuccess = data => dispatch => {
  return dispatch({
    type: AUTH_ACTION_TYPES.AUTH_SUCCESS,
    payload: data,
  });
};

export const setAuthError = msg => ({
  type: AUTH_ACTION_TYPES.AUTH_ERROR,
  msg: msg,
});

export const unsuscribe = () => async dispatch => {
  try {
    await authService.unsuscribe();
    return dispatch({type: AUTH_ACTION_TYPES.UNSUSCRIBE_SUCCESS});
  } catch (error) {
    return {
      type: AUTH_ACTION_TYPES.UNSUSCRIBE_ERROR,
      msg: 'No se pudo dar de baja al usuario.',
    };
  }
};

export const auth = () => async dispatch => {
  try {
    const user = await authService.getUser();
    return dispatch(authSuccess(user));
  } catch (error) {
    localStorage.removeItem('token');
    return setAuthError(error || 'Error al obtener el usuario.');
  }
};

export const logout = () => async dispatch => {
  try {
    await authService.logout();
    localStorage.removeItem('token');
    return dispatch(logoutSuccess());
  } catch (error) {
    return dispatch({ type: AUTH_ACTION_TYPES.AUTH_ERROR, msg: error });
  }
};

export const loginWithGoogle = (accessToken, code) => async dispatch => {
  try {
    const loginResponse = await runFetch(
      `/login/google/?backend=${defaultInstance()}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access_token: accessToken, code: code }),
      }
    );
    const { key, person, groups, user } = loginResponse;
    if (key) {
      localStorage.setItem('token', key);
      return dispatch(fetchUserSuccess(person, groups, key, user));
    }
  } catch (error) {
    dispatch(setAuthError(error ? error : 'Error al ingresar.'));
    return Promise.reject();
  }
};

export const loginWithApple = credentials => async dispatch => {
  try {
    const loginResponse = await runFetch('/login/apple/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });
    const { key, person, groups, user } = loginResponse;
    if (key) {
      localStorage.setItem('token', key);
      return dispatch(fetchUserSuccess(person, groups, key, user));
    } else {
      throw new Error(
        'El usuario no tiene acceso al sistema. Comunicarse con el administrador.'
      );
    }
  } catch (error) {
    dispatch(setAuthError(error ? error : 'Error al ingresar.'));
    return Promise.reject();
  }
};

export const fetchUserStart = () => ({
  type: AUTH_ACTION_TYPES.FETCH_USER_START,
});

export const fetchUserSuccess = (person, roles, token, user) => ({
  type: AUTH_ACTION_TYPES.FETCH_USER_SUCCESS,
  payload: { person, roles, token, user },
});

export const fetchUserFailure = () => ({
  type: AUTH_ACTION_TYPES.FETCH_USER_FAILURE,
});
