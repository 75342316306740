import { PROFILE_ACTION_TYPES } from './profile.types';

const initialState = {
  error: '',
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.TEAM_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
