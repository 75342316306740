import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as teamActions from '../store/team/team.actions';
import * as statsActions from '../store/stats/stats.actions';
import CustomLineBarChartWithLegend from '../components/charts/barLineChart/CustomLineBarChartWithLegend';
import Typography from '@material-ui/core/Typography';
import RpeStats from './RpeStats';
import WellnessStats from './WellnessStats';
import { teamService as tS } from '../services/team.service';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  title: {
    margin: '10px 0 10px 0',
  },
}));

const SquadStats = ({
  match: {
    params: { id },
  },
  history,
  fetchTeamRPEWeek,
}) => {
  const [teamDetail, setTeamDetail] = useState(null);
  const [teamRPEWeek, setTeamRPEWeek] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    new Promise(() => tS.getTeam(id).then((res)=> {setTeamDetail(res)}))
    fetchTeamRPEWeek(id).then((res) => setTeamRPEWeek(res));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRPEWeekReload = filters => {
    const { cantWeek, startWeek } = filters;
    return fetchTeamRPEWeek(id, cantWeek, startWeek);
  };

  const goToProfile = (memberId, personId) => {
    history.push(`/profile/${memberId}/${personId}`);
  };

  return (
    <div className={classes.container}>
      <div>
        {teamDetail && (
          <div>
            <Typography className={classes.title} variant="h4">
              {teamDetail.name}
            </Typography>
          </div>
        )}
        <Grid container xs={12}>
          <Grid xs={6}>
            <WellnessStats id={id} goToProfile={goToProfile} />
          </Grid>
          <Grid xs={6}>
            {teamRPEWeek && (
              <div style={{ padding: '10px 0px' }}>
                <CustomLineBarChartWithLegend
                  title="Carga interna / Carga externa Semanal"
                  type="RPEWeek"
                  data={teamRPEWeek}
                  hasFilters={true}
                  onReload={onRPEWeekReload}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <RpeStats id={id} />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...statsActions, ...teamActions }, dispatch);

export default compose(connect(null, mapDispatchToProps))(
  SquadStats
);
