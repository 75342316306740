import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SelectMultiple from '../../components/SelectMultiple';
import defaultImage from '../../utils/defaultImage';
import FormControl from '@material-ui/core/FormControl';
import { BASE_URL } from '../../constants/config';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const classes = {
    rootField: {'& .MuiFormControl-root': {width: '100%'}},
    select: { width: '100%'}
}

export const defaultColumnsConstructor = (nationalities, genders, sports) => {
    return([
    {
      field: 'image',
      title: '',
      editable: 'never',
      render: (rowData) => {
        return (
          <div>
            {rowData && (
              <img
                alt="personImage"
                src={rowData.image ? BASE_URL + rowData.image : defaultImage()}
                style={{ width: 50, borderRadius: '50%' }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Nombre',
      field: 'firstName',
      editComponent: (props) => (
        <FormControl fullWidth>
          <TextField
            style={classes.rootField}
            placeholder="Nombre *"
            type="text"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Apellido',
      field: 'lastName',
      editComponent: (props) => (
        <FormControl fullWidth>
          <TextField
            style={classes.rootField}
            placeholder="Apellido *"
            type="text"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Email',
      field: 'email',
      editComponent: (props) => (
        <FormControl fullWidth>
          <TextField
            style={classes.rootField}
            placeholder="Email *"
            type="email"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Fecha de nacimiento',
      field: 'birthOfDate',
      render: (rowData) => moment(rowData.birthOfDate).format('DD-MM-YYYY'),
      type: 'date',
      initialEditValue: new Date(),
      cellStyle: {
        width: '150px',
      },
    },
    {
      title: 'Género',
      field: 'gender.id',
      render: (rowData) => rowData.gender.description,
      editComponent: (props) => {
        return (
          <TextField
            select
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            style={classes.select}
          >
            {genders.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.description}
              </MenuItem>
            ))}
          </TextField>
        );
      },
      cellStyle: {
        width: '200px',
      },
    },
    {
      title: 'Nacionalidad',
      field: 'nationality.id',
      render: (rowData) => rowData.nationality.description,
      editComponent: (props) => {
        return (
          <TextField
            select
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            style={classes.select}
          >
            {nationalities.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.description}
              </MenuItem>
            ))}
          </TextField>
        );
      },
      cellStyle: {
        width: '200px',
      },
    },
    {
      title: 'Deportes',
      field: 'sports',
      render: (rowData) => (
        <List dense={true}>
          {rowData.sports.map((sport) => (
            <ListItem key={`item-${sport.id}`}>
              <ListItemText primary={sport.name} />
            </ListItem>
          ))}
        </List>
      ),
      initialEditValue: [],
      editComponent: (props) => (
        <SelectMultiple
          value={props.value}
          onChange={props.onChange}
          options={sports}
        />
      ),
      cellStyle: {
        width: '200px',
      },
    },
  ])}