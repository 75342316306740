import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem'; 
import { green, yellow, orange, red } from '@material-ui/core/colors';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {
    Assignment,
    Warning,
    OfflinePin,
  } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const defaultColumnsConstructor = (handleOpenObservations) => {
    return([
    {
        title: ' ',
        editable: 'never',
        sorting: false,
        render: rowData => {
        const opt1 = Boolean(rowData.returnToTrainingDate);
        const opt2 = Boolean(rowData.returnToTeam);
        const opt3 = Boolean(rowData.returnToPlayDate);
        switch (true) {
            case opt1 && opt2 && opt3:
            return <OfflinePin style={{ color: green[400] }} />;
            case opt1 && !opt2 && !opt3:
            return <OfflinePin style={{ color: orange[400] }} />;
            case opt1 && opt2 && !opt3:
            return <OfflinePin style={{ color: yellow[400] }} />;
            default:
            return <Warning style={{ color: red[400] }} />;
        }
        },
        cellStyle: {
        paddingLeft: '90px',
        },
    },
    {
        title: 'Estado',
        sorting: false,
        editable: 'never',
        render: rowData => {
        const opt1 = Boolean(rowData.returnToTrainingDate);
        const opt2 = Boolean(rowData.returnToTeam);
        const opt3 = Boolean(rowData.returnToPlayDate);
        switch (true) {
            case opt1 && opt2 && opt3:
            return 'Activo';
            case opt1 && !opt2 && !opt3:
            return 'Rehabilitando solo';
            case opt1 && opt2 && !opt3:
            return 'Rehabilitando en equipo';
            default:
            return 'Inactivo';
        }
        },
    },
    {
        title: 'Nombre',
        field: 'person.firstName',
        editable: 'never',
        render: rowData =>
        `${rowData.person.firstName} ${rowData.person.lastName}`,
    },
    {
        title: 'Plantel',
        field: 'teams',
        editable: 'never',
        render: rowData => (
        <List dense={true}>
            {rowData.teams &&
            rowData.teams.map(team => (
                <ListItem key={team.id} style={{paddingLeft: '0%'}}>
                <ListItemText primary={team.name} />
                </ListItem>
            ))}
        </List>
        ),
    },
    {
        title: 'Tipo de lesion',
        field: 'type',
        render: rowData =>
        rowData.type === 'traumatic' ? 'Traumática' : 'No traumática',
        editComponent: props => {
        return (
            <TextField
            select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            style={{width: '100%'}}
            >
            <MenuItem key="traumatic" value="traumatic">
                Traumática
            </MenuItem>
            <MenuItem key="no_traumatic" value="no_traumatic">
                No traumática
            </MenuItem>
            </TextField>
        );
        },
        cellStyle: {
        width: '20%',
        },
    },
    {
        title: 'Observaciones',
        editable: 'never',
        sorting: false,
        render: rowData => (
                <Button
                    style={{
                        textTransform: 'none',
                        fontFamily: 'Arial',
                        width: '130px',
                        paddingLeft: '0%',
                        color: '#3c9ee5',
                      }}
                    size="large"
                    startIcon={<Assignment />}
                onClick={() => handleOpenObservations(rowData.id)}
                >
                Ver detalle
                </Button>
            )
    },
    {
        title: 'Fecha de lesión',
        field: 'date',
        render: rowData => moment(rowData.date).format('DD/MM/YYYY'),
        type: 'date',
        initialEditValue: new Date(),
        headerStyle: {
        fontSize: 'small',
        marginLeft: '0%',
        marginRight: '0%',
        },
    },
])}