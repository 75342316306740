import { STATS_ACTION_TYPES } from './stats.types';

const initialState = {
  teamRPE: [],
};

export default function stats (state = initialState, action) {
  switch (action.type) {
    case STATS_ACTION_TYPES.FETCH_TEAM_RPE_SUCCESS:
      return Object.assign({}, state, {
        teamRPE: action.payload,
      });
    default:
      return state;
  }
}
