import React, {useState} from 'react';
import { compose, bindActionCreators } from 'redux';
import { history } from '../store/store';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  makeStyles,
  Button, CircularProgress, Grid
} from '@material-ui/core';

import {authRequested, logout, unsuscribe} from '../store/auth/auth.actions';



const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  title: {
    margin: '10px 0 10px 0',
  },
}));

const UnsuscribeScreen = ({authRequested, logout, unsuscribe}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true)
  const [openSecond, setOpenSecond] = useState(false)
  const [logoutProcess, setLogoutProcess] = useState(false)


  const closeContinue = ()=> {
    setOpen(false)
    setOpenSecond(true)
  }

  const closeBack = ()=> {
    setOpenSecond(false)
    setOpen(true)
  }

  const close = ()=> {
    setOpen(false)
    history.push('/');
  }
  
  const closeContinueSecond = ()=> {
    setLogoutProcess(true)
    setOpenSecond(false)
    unsuscribe()
    doLogout()
  }

  const doLogout = response => {
    authRequested(true);
    logout().then(() => {authRequested(false); setLogoutProcess(false)});
  };

  return (
    <div className={classes.container}>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Darse de baja"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro que quiere dejar de ser usuario de MACHI?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeContinue}>Si</Button>
          <Button onClick={close} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSecond}
        onClose={closeBack}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Darse de baja"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container justifyContent="center">
              {logoutProcess ? <CircularProgress/> : 'El usuario será dado de baja y redirigido a la página de inicio de sesión. No podrá volver a iniciar sesión con este usuario. ¿Desea continuar?'}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeContinueSecond}>Aceptar</Button>
          <Button onClick={closeBack} autoFocus>
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ authRequested, logout, unsuscribe }, dispatch);

export default compose(connect(null, mapDispatchToProps))(
  UnsuscribeScreen
);