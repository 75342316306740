import moment from 'moment';

export const defaultColumns = [
    {
      title: 'Nombre',
      field: 'person.firstName',
      editable: 'never',
      render: rowData =>
        `${rowData.person.firstName} ${rowData.person.lastName}`,
    },
    {
      title: 'Etapa del año',
      field: 'stage.name',
      editable: 'never',
      render: rowData => `${rowData.stage.name}`,
    },
    {
      title: 'Fecha de evaluación',
      field: 'date',
      render: rowData => moment(rowData.date).format('DD/MM/YYYY'),
      type: 'date',
      initialEditValue: new Date(),
      headerStyle: {
        fontSize: 'small',
        marginLeft: '0%',
        marginRight: '0%',
      },
    },
    {
      title: 'Peso',
      field: 'weight',
      editable: 'never',
      render: rowData => `${rowData.weight} `,
    },
    {
      title: 'Talla',
      field: 'height',
      editable: 'never',
      render: rowData => `${rowData.height}`,
    },
    {
      title: 'Indice de masa corporal',
      field: 'bodyMassIndex',
      editable: 'never',
      render: rowData => `${rowData.bodyMassIndex}`,
    },
    {
      title: 'Objetivo',
      field: 'purpose.description',
      editable: 'never',
      render: rowData => `${rowData.purpose.description}`,
    },
  ];
