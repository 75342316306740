
import { STATS_ACTION_TYPES } from './stats.types'
import { statsService } from '../../services/stats.service';
import {
  DEFAULT_DAYS,
  DEFAULT_DAYS_AL,
  DEFAULT_DAYS_MONOTONY,
  DEFAULT_OFFSET_CL,
  DEFAULT_WEEKS,
  DEFAULT_START_WEEK,
} from '../../constants/chart';

export const fetchTeamRPE = (
  idTeam,
  days = DEFAULT_DAYS.VALUE,
  offsetCl = DEFAULT_OFFSET_CL.VALUE,
  cantDaysAl = DEFAULT_DAYS_AL.VALUE,
  cantDaysMonotony = DEFAULT_DAYS_MONOTONY.VALUE
) => async dispatch => {
  try {
    const response = await statsService.getTeamRPE(idTeam,days,offsetCl,cantDaysAl,cantDaysMonotony);
    response
      && dispatch({
          type: STATS_ACTION_TYPES.FETCH_TEAM_RPE_SUCCESS,
          payload: response
        })
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const fetchTeamRPEWeek = (
  idTeam,
  cantWeeks = DEFAULT_WEEKS.VALUE,
  startWeek = DEFAULT_START_WEEK.VALUE
) => async dispatch => {
  try {
    const response = await statsService.getTeamRPEWeek(idTeam,cantWeeks, startWeek);
    response
      && dispatch({
          type: STATS_ACTION_TYPES.FETCH_TEAM_RPE_WEEK_SUCCESS,
        })
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const fetchTeamRPEByPosition = (
  idTeam,
  position,
  days = DEFAULT_DAYS.VALUE,
  offsetCl = DEFAULT_OFFSET_CL.VALUE,
  cantDaysAl = DEFAULT_DAYS_AL.VALUE,
  cantDaysMonotony = DEFAULT_DAYS_MONOTONY.VALUE
) => async dispatch => {
  try {
    const response = await statsService.getTeamRPEByPosition(idTeam, position, days,offsetCl,cantDaysAl,cantDaysMonotony);
    response
      && dispatch({
          type: STATS_ACTION_TYPES.FETCH_TEAM_RPE_WEEK_SUCCESS,
        })
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

  

