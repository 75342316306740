import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import { format } from 'date-fns';
import moment from 'moment';
import AddMemberDialog from '../components/modals/AddMemberDialog';
import * as personActions from '../store/person/person.actions';
import * as teamActions from '../store/team/team.actions';
import { updateMember, deleteMember } from '../store/member/member.actions';
import { fetchPositions } from '../store/position/position.actions';
import { setSuccessMsg } from '../store/app/app.actions';
import {
  positionsSelector,
} from '../store/selectors';
import { translations } from '../constants/table-localization';
import ImageUploadDialog from '../components/modals/ImageUploadDialog';
import { defaultColumnsConstructor } from '../constants/table/squadDetailColumns';
import { teamService as tS } from '../services/team.service';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  categorySelect: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const SquadDetail = ({
  match: {
    params: { id },
  },
  resetTeam,
  fetchTeamMembers,
  resetTeamMembers,
  history,
  deleteMember,
  fetchPositions,
  positions,
  updateMember,
  uploadPersonImage,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [teamDetail, setTeamDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const tableOptions = {
    search: true,
    sorting: false,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize,
    paging: false,
  };
  
  const fetchScreenInformation = () => {
    return Promise.all([
      tS.getTeam(id).then((res)=> setTeamDetail(res)),
      fetchTeamMembers(id).then((res)=>setTeamMembers(res))
    ]);
  }; 

  useEffect(() => {
    setLoading(true);
    fetchScreenInformation().finally((res) =>setLoading(false));
    if(positions.length === 0) fetchPositions(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPlayerDetail = (id, personID) => {
    history.push(`/profile/${id}/${personID}`);
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const onMemberSubmit = () => {
    setLoading(true)
    fetchTeamMembers(id).then((res)=>{setTeamMembers(res); setLoading(false)});
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const onImageUploadSubmit = image => {
    uploadPersonImage(selectedMember.person.id, image).then(() => {
      setSuccessMsg('Imagen cargada correctamente')
      setLoading(true);
      fetchTeamMembers(id).then((res)=>{setTeamMembers(res); setLoading(false)});
    });
    setImageUploadDialogOpen(false);
  };
  const onImageUploadClose = () => {
    setImageUploadDialogOpen(false);
  };

  const activeMembership = member => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date(teamDetail?.validityDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0, 0, 0, 0);
    return today <= endDate;
  };

  return (
    <div className={classes.container}>
      {selectedMember.person && (
        <ImageUploadDialog
          open={imageUploadDialogOpen}
          onSubmit={onImageUploadSubmit}
          onClose={onImageUploadClose}
          fileName={`profile_${selectedMember.person.id}`}
          team={teamDetail}
        />
      )}
      <AddMemberDialog
        teamDetail={teamDetail}
        onSubmit={onMemberSubmit}
        open={dialogOpen}
        onClose={handleClose}
      />
      <MaterialTable
        title={
          <Typography variant="h5" color="textPrimary">
            {teamDetail?.name}
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={defaultColumnsConstructor(positions)}
        options={tableOptions}
        isLoadind={loading}
        localization={translations}
        data={teamMembers}
        actions={[
          {
            icon: 'person_add',
            tooltip: 'Agregar miembro',
            isFreeAction: true,
            iconProps: {
              color: 'primary',
            },
            onClick: event => handleClickOpen(),
          },
          rowData => {
            return rowData.player
              ? {
                  icon: 'list_alt',
                  tooltip: 'Ver ficha',
                  disabled: !activeMembership(), 
                  onClick: (event, rowData) => {
                    goToPlayerDetail(rowData.id, rowData.person.id);
                  },
                }
              : {
                  hidden: true,
                };
          },
          {
            onClick: (event, rowData) => {
              setSelectedMember(rowData);
              setImageUploadDialogOpen(true);
            },
            icon: 'add_a_photo',
            tooltip: 'Cargar foto',
          },
        ]}
        editable={{
          isDeletable: rowData => true,
          isEditable: rowData => true,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              let newMember = {
                id: oldData.id,
                team: teamDetail?.id,
                roleTeam: oldData.roleTeam.id,
                person: oldData.person.id,
                initialDate: oldData.initialDate,
                endDate: 
                format(
                  new Date(moment(newData.endDate).toISOString()),
                  'yyyy-MM-dd'
                ),
              };
              if (oldData.player) {
                if (newData.player.position) {
                  newMember = {
                    ...newMember,
                    position: newData.player.position.id,
                  };
                }
                if (newData.player.number) {
                  newMember = {
                    ...newMember,
                    number: newData.player.number,
                  };
                }
              }
              updateMember(newMember).then(res => {
                setSuccessMsg('Miembro actualizado correctamente');
                resolve();
              })
              .finally(() =>{
                setLoading(true);
                fetchTeamMembers(id).then((res)=>{setTeamMembers(res); setLoading(false)});
              });
            }),
          onRowDelete: rowData =>
            new Promise((resolve, reject) => {
              deleteMember(rowData.id).then(res => {
                setSuccessMsg('Miembro eliminado correctamente');
                resolve();
              })
              .finally(() =>{
                setLoading(true);
                fetchTeamMembers(id).then((res)=>{setTeamMembers(res); setLoading(false)});
              });
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  positions: positionsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    ...teamActions, 
    ...personActions, 
    setSuccessMsg, 
    updateMember, 
    deleteMember,
    fetchPositions,
  }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SquadDetail
);
