import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import {
  Edit,
  DeleteSweep,
} from '@material-ui/icons';
import { defaultColumnsConstructor } from '../constants/table/injuryListColumns';
import { translations } from '../constants/table-localization';
import { injuriesSelector } from '../store/selectors';
import * as injuryActions from '../store/injury/injury.actions';
import ConfirmationDialog from '../components/ConfimationDialog';
import ObservacionesModal from '../components/modals/ObservacionesModal';
import { setSuccessMsg } from '../store/app/app.actions';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(3),
    fontFamily: 'Arial',
    '& .MuiTableHead-root': {
      borderTop: '1px solid #e0e0e0',
      borderBottom: '2px solid #e0e0e0',
    },
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  stateIcon: {
    paddingLeft: '20%',
  },
  actionIcon: {
    paddingLeft: '120px',
    color: 'action',
  },
}));

translations.header.actions = '';

const InjuryList = ({
  setInjury,
  fetchInjuries,
  injuries,
  deleteInjury,
  setMostrarForm,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [injurySelected, setInjurySelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openObservations, setOpenObservations] = useState(false);
  const [observationsInjury, setObservationsInjury] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => { 
    if(injuries.length === 0) {
    setLoading(true);
    fetchInjuries()
      .then(() => setLoading(false))
      .catch(() => setLoading(false)); 
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event, injury) => {
    setInjurySelected(injury);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToTop = () => window.scrollTo(0, 0);

  const handleOnEdit = () => {
    setInjury(injurySelected);
    scrollToTop();
  };

  const handleOnDelete = () => {
    if (injurySelected) {
      setLoading(true);
      deleteInjury(injurySelected.id)
        .then(res => {
          setSuccessMsg('Lesion eliminada correctamente')
          setLoading(false);
          handleClose();
          setOpenConfirm(false);
          fetchInjuries();
        })
        .catch(() => {
          setLoading(false);
          handleClose();
          setOpenConfirm(false);
        });
    }
  };

  const confirmDelete = () => {
    setConfirmMessage(() => (
      <span>
        ¿Está seguro que desea <b>eliminar</b> la lesión?
      </span>
    ));
    setOpenConfirm(true);
  };

  const handleOpenObservations = id => {
    setObservationsInjury(id);
    setOpenObservations(true);
  };

  const handleCloseObservations = () => {
    setObservationsInjury(null);
    setOpenObservations(false);
  };

  const tableOptions = {
    search: false,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
    tableLayout: '',
  };

  return (
    <div className={classes.container}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onBlur={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem
            button
            disabled={loading}
            onClick={() => {
              handleOnEdit();
              setMostrarForm(true);
            }}
          >
            <ListItemText primary="Editar" />
            <Edit className={classes.actionIcon} color="action" />
          </ListItem>
          <ListItem button disabled={loading} onClick={confirmDelete}>
            <ListItemText primary="Eliminar" />
            <DeleteSweep className={classes.actionIcon} color="action" />
          </ListItem>
        </List>
      </Popover>
      <ConfirmationDialog
        loading={loading}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        message={confirmMessage}
        onConfirm={handleOnDelete}
        setLoading={setLoading}
      />
      <ObservacionesModal
        open={openObservations}
        onClose={handleCloseObservations}
        injuryId={observationsInjury}
      />
      <MaterialTable
        className={classes.table}
        title={
          <Typography variant="h6" color="textPrimary" align="center">
            Lesiones
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={defaultColumnsConstructor(handleOpenObservations)}
        options={tableOptions}
        localization={translations}
        isLoading={loading}
        data={injuries}
        actions={[
          {
            icon: 'more_vert',
            tooltip: 'más',
            iconProps: { color: 'action' },
            onClick: (event, rowData) => {
              handleClick(event, rowData);
            },
          },
        ]}
        editable={{
          isEditable: rowData => false,
          isDeletable: rowData => true,
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  injuries: injuriesSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...injuryActions,
      setSuccessMsg,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InjuryList
);
