import {
  DEFAULT_DAYS,
  DEFAULT_DAYS_AL,
  DEFAULT_DAYS_MONOTONY,
  DEFAULT_OFFSET_CL,
} from '../../constants/chart';


import { profileService } from '../../services/profile.service';
import { PROFILE_ACTION_TYPES } from './profile.types';

export const fetchTeamPlayer = (id) => async (dispatch) => {
  try {
    const response = await profileService.getTeamPlayer(id);
    response
      ? dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_TEAM_PLAYER_SUCCESS,
        })
      : dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_TEAM_PLAYER_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const fetchRPEPlayer = (
  idMember,
  days = DEFAULT_DAYS.VALUE,
  offsetCl = DEFAULT_OFFSET_CL.VALUE,
  cantDaysAl = DEFAULT_DAYS_AL.VALUE,
  cantDaysMonotony = DEFAULT_DAYS_MONOTONY.VALUE
) => async (dispatch) => {
  try {
    const response = await profileService.getRPEPlayer(idMember,days,offsetCl,cantDaysAl,cantDaysMonotony);
    response
      ? dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_RPE_PLAYER_SUCCESS,
        })
      : dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_RPE_PLAYER_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchWellnessPlayer = (id) => async (dispatch) => {
  try {
    const response = await profileService.getWellnessPlayer(id)
    response
      ? dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_PLAYER_SUCCESS,
      })
      : dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_PLAYER_ERROR
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }

};

export const fetchWellnessHistoricalPlayer = (
  idMember,
  days = DEFAULT_DAYS.VALUE
) => async (dispatch) => {
  try {
    const response = await profileService.getWellnessHistoricalPlayer(idMember, days)
    response
      ? dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_HISTORICAL_PLAYER_SUCCESS,
        })
      : dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_HISTORICAL_PLAYER_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
