import React, { useEffect, useState } from 'react';
import TableStats from './TableStats';
import LoadingSpinner from '../components/LoadingSpinner';
import { statsService as sS } from '../services/stats.service'

const WellnessStats = ({ id, goToProfile }) => {
  const [loading, setLoading] = useState(true);
  const [teamWellness, setTeamWellness] = useState(null)

  useEffect(() => {
    setLoading(true);
    new Promise(() => sS.getWellnessTeam(id).then((res) => {setTeamWellness(res); setLoading(false)}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  return (
    <div>
      {loading ? (
        <LoadingSpinner/>
      ) : (
        <TableStats allMembersWellness={teamWellness} goToProfile={goToProfile}/>
      )
      }
    </div>
  );
};

export default WellnessStats;